import cn from 'classnames';
import React, { FunctionComponent, useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { isSettingsRoute, isZonesRoute, ROUTES } from 'Consts/routes';

import Icon from 'UI/Elements/Icon';
import PillWeb from 'UI/Elements/Pill web';

import { getIconFromRoute, NavPanelItemProps, Props } from '../../index';

import useCspTranslationNamespace from 'Utils/hooks/useCspTranslationNamespace';
import { onEnterKeydown } from 'Utils/keyboardEvents';
import styles from './style.module.css';
import useCustomerSupportConfigurations from 'State/hooks/useCustomerSupportConfigurations';
import { isPureDevDomain } from 'subDomainConfiguration';

import { memo } from 'react';

type ZonePillProps = NavPanelItemProps & {
  label: string;
};

export const NavButton: FunctionComponent<NavPanelItemProps> = ({
  currentRoute,
  route,
  onNavigate,
}) => {
  const handleNavigate = useCallback(() => {
    onNavigate(route);
  }, [route, onNavigate]);

  const selected =
    (isZonesRoute(currentRoute) && isZonesRoute(route)) ||
    (isSettingsRoute(currentRoute) && isSettingsRoute(route)) ||
    currentRoute === route;
  const classNames = cn(styles.navButton, { [styles.selected]: selected });

  return (
    <div
      className={classNames}
      onClick={handleNavigate}
      role="link"
      tabIndex={0}
      onKeyDown={(e) => onEnterKeydown(e, handleNavigate)}
    >
      <Icon name={getIconFromRoute(route, selected)} />
    </div>
  );
};

const ZonePill: FunctionComponent<ZonePillProps> = ({
  label,
  currentRoute,
  route,
  additionalRoutesForSelection,
  onNavigate,
}) => {
  const handleNavigate = useCallback(() => {
    onNavigate(route);
  }, [route, onNavigate]);

  const selected =
    currentRoute === route ||
    additionalRoutesForSelection?.includes(currentRoute);

  return (
    <PillWeb
      className={styles.zonePill}
      label={label}
      isSelected={selected}
      onClick={handleNavigate}
    />
  );
};

const MobileNavPanelUI: FunctionComponent<Props> = ({
  className,
  currentRoute,
  onNavigate,
}) => {
  const { t } = useTranslation();
  const namespace = useCspTranslationNamespace();
  const classNames = cn(styles.mobileNavPanel, className);

  const { data: customerSupportConfigurations } =
    useCustomerSupportConfigurations();
  const insuranceEnabled =
    customerSupportConfigurations?.customerFeatureEnablement
      .protectInsuranceEnabled;
  const businessInfoEnabled = isPureDevDomain();

  return (
    <div className={classNames} role="navigation">
      {isZonesRoute(currentRoute) && (
        <div className={styles.zonesNavBar}>
          <div className={styles.overflowContainer}>
            <ZonePill
              label={t('common.secureZone')}
              currentRoute={currentRoute}
              route={ROUTES.zones.secure.index}
              onNavigate={onNavigate}
            />

            <ZonePill
              label={t('common.employeeZone')}
              currentRoute={currentRoute}
              route={ROUTES.zones.employee.index}
              onNavigate={onNavigate}
            />

            <ZonePill
              label={t('common.guestZone')}
              currentRoute={currentRoute}
              route={ROUTES.zones.guest.index}
              onNavigate={onNavigate}
            />
          </div>
        </div>
      )}

      {isSettingsRoute(currentRoute) && (
        <div className={styles.settingsNavBar}>
          <ZonePill
            label={t('settings.secure', { ns: namespace })}
            currentRoute={currentRoute}
            route={ROUTES.settings.secure}
            onNavigate={onNavigate}
          />

          <ZonePill
            label={t('settings.employee', { ns: namespace })}
            currentRoute={currentRoute}
            route={ROUTES.settings.employee}
            additionalRoutesForSelection={[ROUTES.settings.employeeLogin]}
            onNavigate={onNavigate}
          />

          <ZonePill
            label={t('settings.guest', { ns: namespace })}
            currentRoute={currentRoute}
            route={ROUTES.settings.guest}
            onNavigate={onNavigate}
          />

          <ZonePill
            label={t('settings.shieldSettings')}
            route={ROUTES.settings.shield}
            currentRoute={currentRoute}
            onNavigate={onNavigate}
          />

          {insuranceEnabled && (
            <ZonePill
              label={t('common.insurance')}
              currentRoute={currentRoute}
              route={ROUTES.insurance.index}
              onNavigate={onNavigate}
            />
          )}

          {businessInfoEnabled && (
            <ZonePill
              label={t('settings.businessInfo.title')}
              route={ROUTES.settings.businessInfo}
              currentRoute={currentRoute}
              onNavigate={onNavigate}
            />
          )}

          <ZonePill
            label={t('settings.pods', { ns: namespace })}
            route={ROUTES.settings.pods}
            currentRoute={currentRoute}
            onNavigate={onNavigate}
          />
          <ZonePill
            label={t('settings.support')}
            route={ROUTES.settings.support}
            currentRoute={currentRoute}
            onNavigate={onNavigate}
          />
          <ZonePill
            label={t('settings.account')}
            route={ROUTES.settings.account}
            currentRoute={currentRoute}
            onNavigate={onNavigate}
          />
        </div>
      )}

      <div className={styles.pagesNavBar}>
        <NavButton
          currentRoute={currentRoute}
          route={ROUTES.home.index}
          onNavigate={onNavigate}
        />

        <NavButton
          currentRoute={currentRoute}
          route={ROUTES.devices}
          onNavigate={onNavigate}
        />

        <NavButton
          currentRoute={currentRoute}
          route={ROUTES.zones.secure.index}
          onNavigate={onNavigate}
        />

        <NavButton
          currentRoute={currentRoute}
          route={ROUTES.shield}
          onNavigate={onNavigate}
        />

        <NavButton
          currentRoute={currentRoute}
          route={ROUTES.network}
          onNavigate={onNavigate}
        />

        <NavButton
          currentRoute={currentRoute}
          route={ROUTES.settings.secure}
          onNavigate={onNavigate}
        />
      </div>
    </div>
  );
};
export default memo(MobileNavPanelUI);
